import { useNavigate } from 'react-router-dom';
import './__dashboard.scss';

// // // // // // // // // // // // // // // // // // // // // // // // // // // //

export default function Dashboard(){
    const navigate = useNavigate();

    const goToHeaders = () => {navigate('/dashboard/headers');};
    const goToTypes = () => {navigate('/dashboard/types');};
    const goToContents = () => {navigate('/dashboard/contents');};

    return(
        <article className='dashboard'>
            <section className='dashboard__section' onClick={goToHeaders}>Zakładka</section>
            <section className='dashboard__section' onClick={goToTypes}>Typ</section>
            <section className='dashboard__section' onClick={goToContents}>Treść</section>
        </article>
    );
}