import { useState, useEffect } from 'react';
import { Button, Form, Input, List } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { signout } from '../../services/authService';
import { getHeaders, addHeader, deleteHeader } from '../../services/headerService';
import './__header.scss';

// // // // // // // // // // // // // // // // // // // // // // // // // // // //

export default function Header(){
    
    const [headers, setHeaders] = useState([]);

    useEffect(() => {
        fetchHeaders();
    }, []);

    const fetchHeaders = async () => {
        try{
            const response = await getHeaders();
            const headersData = response.map(header => {
                return {
                    id: header.id,
                    name: header.name
                }
            });
            
            setHeaders(headersData);
        } catch(error){
            console.error("Error fetching headers", error);
            signout();
        }
    };

    const adding = async (data) => {
        try{
            await addHeader(data.header);
            fetchHeaders();
        } catch(error){
            console.error("Error adding headers", error);
            signout();
        }
    };

    const deleting = async (header) => {
        try{
            await deleteHeader(header);
            fetchHeaders();
        } catch(error){
            console.error("Error deleting headers", error);
            signout();
        }
    };

    return(
        <article className='header__article'>
            <Form className='header' layout='vertical' onFinish={adding}>
                <Form.Item
                    name="header"
                    className='header__item'
                    rules={[{ required: true, message: 'Wprowadź nazwę zakładki!' }]}
                >
                    <Input className='header__input'/>
                </Form.Item>
                <Button htmlType="submit" className="header__button" icon={<PlusOutlined />}></Button> 
            </Form>
            <List
                className='header__list'
                itemLayout="horizontal"
                dataSource={headers}
                renderItem={header => (
                    <List.Item
                        key={header.id}
                        className='header__list--item'
                        actions={[ <Button className='header__button--delete' icon={<MinusOutlined />} onClick={() => deleting(header.id)}></Button> ]}
                    >
                        <List.Item.Meta title={header.name}/>
                    </List.Item>
                )}
            />
        </article>
    );
}