import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { signin } from '../../services/authService';
import './__home.scss';

// // // // // // // // // // // // // // // // // // // // // // // // // // // //

export default function Home(){

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleOnSubmit = async (credentials) => {
        try{
            await handleLoading(true);
            await signin(credentials.login, credentials.password);
            handleLoading(false);
            await handleSuccess(true);
            navigate('/dashboard');
        } catch(error){
            setError("Wprowadzone dane są niepoprawne.");
            handleLoading(false);
        }
    };

    const handleLoading = async (status) => {
        setLoading(status);
        await new Promise((resolve) => setTimeout(resolve, 2000));
    };

    const handleSuccess = async (status) => {
        setSuccess(status);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setSuccess(!status);
    }

    return(
        <Form className='home' layout='vertical' onFinish={handleOnSubmit}>
            <Form.Item className='home__item--icon'>
                <span className='material-symbols-outlined icon'>account_circle</span>
            </Form.Item>

            <Form.Item
                name="login"
                className='home__item--input'
                rules={[{ required: true, message: 'Wprowadź login!' }]}
                validateStatus={error ? "error" : ""}
                help={error}
                hasFeedback
            >
                <Input className='home__input' prefix={<UserOutlined style={{color: '#fff'}} />} />
            </Form.Item>

            <Form.Item
                name="password"
                className='home__item--input'
                rules={[{ required: true, message: 'Wprowadź hasło!' }]}
                validateStatus={error ? "error" : ""}
                help={error}
                hasFeedback
            >
                <Input.Password className='home__input'	prefix={<LockOutlined style={{color: '#fff'}}/>} />
            </Form.Item>

            <Form.Item className='home__item--input'>
                <Button htmlType="submit" className="home__button" loading={loading}>
                    {success && <CheckCircleOutlined className='home__icon--success' />}
                    Zaloguj
                </Button>
            </Form.Item>
        </Form>
    );
}