import { useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, List, Form, Input, Upload } from 'antd';
import { PlusOutlined, MinusOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { API_URL_DEV, signout } from '../../services/authService';
import { getContents, addContent, deleteContent } from '../../services/contentService';
import { getTypes } from '../../services/typeService';
import { getHeaders} from '../../services/headerService';
import './__content.scss';

// // // // // // // // // // // // // // // // // // // // // // // // // // // //

export default function Content(){
    
    const [headers, setHeaders] = useState([]);
    const [types, setTypes] = useState([]);
    const [contents, setContents] = useState([]);

    const [header, setHeader] = useState('');
    const [type, setType] = useState('');
    const [content, setContent] = useState('');

    const [showFile, setShowFile] = useState(false);
    const [showText, setShowText] = useState(false);

    useEffect(() => {
        fetchHeadersAndTypes();
    }, []);

    const fetchHeadersAndTypes = async () => {
        try{
            const responseHeaders = await getHeaders();
            const responseTypes = await getTypes();
            const headersData = responseHeaders.map(header => {
                return {
                    id: header.id,
                    name: header.name
                }
            });

            const typesData = responseTypes.map(type => {
                return {
                    id: type.id,
                    name: type.name
                }
            });
            
            setTypes(typesData);
            setHeaders(headersData);
        } catch(error){
            console.error("Error fetching headers and types", error);
            signout();
        }
    };

    const handleHeaderChange = async (e) => {
        e.preventDefault();
        setHeader(e.target.value);

        try{
            const response = await getContents(e.target.value);
            const contentsData = response.map(content => {
                return {
                    id: content.id,
                    name: content.text
                }
            });
            
            setContents(contentsData);
        } catch(error){
            console.error("Error fetching content", error);
            signout();
        }
    };

    const handleTypeChange = async (e) => {
        e.preventDefault();
        setType(e.target.value);
        const selectedTypeName = e.target.options[e.target.selectedIndex].text;
        console.log(selectedTypeName);
        switch(selectedTypeName) {
            case 'tekst': setShowFile(false); setShowText(true); break;
            case 'docx': setShowText(false); setShowFile(true); break;
            case 'pdf': setShowText(false); setShowFile(true); break;
            case 'jpg': setShowText(false); setShowFile(true); break;
            case 'png': setShowText(false); setShowFile(true); break;
            default: setShowFile(false); setShowText(false);
        }
    };

    const adding = async () => {
        try{
            await addContent(header, type, content);
            const response = await getContents(header);
            const contentsData = response.map(content => {
                return {
                    id: content.id,
                    name: content.text
                }
            });
            
            setContents(contentsData);
        } catch(error){
            console.error("Error adding content", error);
            signout();
        }
    };

    const deleting = async (content) => {
        try{
            await deleteContent(content);
            const response = await getContents(header);
            const contentsData = response.map(content => {
                return {
                    id: content.id,
                    name: content.text
                }
            });
            
            setContents(contentsData);
        } catch(error){
            console.error("Error deleting content", error);
            signout();
        }
    };

    return(
        <article className='content__article'>
            <section className='content__section'>
                <label htmlFor="header" className="content__label">Wybierz zakładkę</label>
                <select id="header" name="headerId" className="content__select" value={header} onChange={handleHeaderChange}>
                    <option value="" disabled selected>Wybierz</option>
                    {headers.map(header => (
                        <option className='content__option' key={header.id} value={header.id}>{header.name}</option>
                    ))}
                </select>
                
                <label htmlFor="type" className="content__label">Wybierz rodzaj</label>
                <select id="type" name="typeId" className="content__select" value={type} onChange={handleTypeChange}>
                    <option value="" disabled selected>Wybierz</option>
                    {types.map(type => (
                        <option className='content__option' key={type.id} value={type.id}>{type.name}</option>
                    ))}
                </select>

                {showFile &&  
                    <Upload.Dragger
                        name="files"
                        action={`${API_URL_DEV}/dashboard/${header}/content/file`}                  
                        className='content__upload'>
                        <CloudUploadOutlined  className='content__icon--upload'/>
                        <p className="ant-upload-hint">Click or drag file to this area to upload</p>
                    </Upload.Dragger>
                }

                {showText && 
                    <div className="content__div">
                        <label htmlFor="text" className="content__label">Podaj treść</label>
                        <textarea id="text" name="textContent" className="content__textarea" rows="10" value={content} onChange={(e) => setContent(e.target.value)}></textarea>
                        <button className="content__button" onClick={adding}>WYKONAJ</button>
                    </div>
                }
            </section>
            <section className='content__section'>
                <List
                    className='content__list'
                    itemLayout="horizontal"
                    dataSource={contents}
                    renderItem={content => (
                        <List.Item
                            key={content.id}
                            className='content__list--item'
                            actions={[ <Button className='content__button--delete' icon={<MinusOutlined />} onClick={() => deleting(content.id)}></Button> ]}
                        >
                            <List.Item.Meta title={content.name}/>
                        </List.Item>
                    )}
                />
            </section>
        </article>
    );
}