import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Dashboard from './pages/Dashboard/Dashboard';
import Header from './pages/Header/Header';
import Type from './pages/Type/Type';
import Content from './pages/Content/Content';

// // // // // // // // // // // // // // // // // // // // // // // // // // // //

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/dashboard/headers' element={<Header />} />
          <Route path='/dashboard/types' element={<Type />} />
          <Route path='/dashboard/contents' element={<Content />} />
          {/* <Route path='/*' element={<NoPage />} /> */}
      </Routes>
    </BrowserRouter>
  );
}