import axios from 'axios';
import { API_URL_DEV } from './authService';

export const getHeaders = async () => {
    // validation();
    const response = await axios.get(`${API_URL_DEV}/dashboard/header`);
    return response.data;
};

export const addHeader = async (header) => {
    // validation();
    const headerDTO = { id: null, sectionID: null, name: header };
    const response = await axios.post(`${API_URL_DEV}/dashboard/header`, headerDTO);
    return response.data;
};

export const deleteHeader = async (id) => {
    // validation();
    const response = await axios.delete(`${API_URL_DEV}/dashboard/header/${id}`);
    return response.data;
};