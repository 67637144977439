import { useState, useEffect } from 'react';
import { Button, Form, Input, List } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { signout } from '../../services/authService';
import { getTypes, addType, deleteType } from '../../services/typeService';
import './__type.scss';

// // // // // // // // // // // // // // // // // // // // // // // // // // // //

export default function Type(){
    
    const [types, setTypes] = useState([]);

    useEffect(() => {
        fetchTypes();
    }, []);

    const fetchTypes = async () => {
        try{
            const response = await getTypes();
            const typesData = response.map(type => {
                return {
                    id: type.id,
                    name: type.name
                }
            });
            
            setTypes(typesData);
        } catch(error){
            console.error("Error fetching types", error);
            signout();
        }
    };

    const adding = async (data) => {
        try{
            await addType(data.type);
            fetchTypes();
        } catch(error){
            console.error("Error adding types", error);
            signout();
        }
    };

    const deleting = async (type) => {
        try{
            await deleteType(type);
            fetchTypes();
        } catch(error){
            console.error("Error deleting types", error);
            signout();
        }
    };

    return(
        <article className='type__article'>
            <Form className='type' layout='vertical' onFinish={adding}>
                <Form.Item
                    name="type"
                    className='type__item'
                    rules={[{ required: true, message: 'Wprowadź nazwę typu!' }]}
                >
                    <Input className='type__input'/>
                </Form.Item>
                <Button htmlType="submit" className="type__button" icon={<PlusOutlined />}></Button> 
            </Form>
            <List
                className='type__list'
                itemLayout="horizontal"
                dataSource={types}
                renderItem={type => (
                    <List.Item
                        key={type.id}
                        className='type__list--item'
                        actions={[ <Button className='type__button--delete' icon={<MinusOutlined />} onClick={() => deleting(type.id)}></Button> ]}
                    >
                        <List.Item.Meta title={type.name}/>
                    </List.Item>
                )}
            />
        </article>
    );
}