import axios from 'axios';
// import jwtDecode from 'jwt-decode';
// import { jwtDecode } from 'jwt-decode';

export const API_URL_DEV = 'https://system.wnmp.eu';

export const signin = async (login, password) => {
    const response = await axios.post(`${API_URL_DEV}/auth/signin`, { login, password });
    localStorage.setItem('login', response.data.login);
    localStorage.setItem('authority', response.data.authority);
    localStorage.setItem('jwt', response.data.token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
    return response;
};

export const signout = () => {
    localStorage.removeItem('login');
    localStorage.removeItem('authority');
    localStorage.removeItem('jwt');
    delete axios.defaults.headers.common['Authorization'];
};

// export const validation = () => {
//     const token = localStorage.getItem('jwtToken');
//     const decodedToken = jwtDecode(token);
//     const currentDate = new Date();
//     const expDate = new Date(decodedToken.exp * 1000);
//     if (expDate < currentDate){
//         throw new Error("Token expired!"); 
//     }
// };