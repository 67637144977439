import axios from 'axios';
import { API_URL_DEV } from './authService';

export const getTypes = async () => {
    // validation();
    const response = await axios.get(`${API_URL_DEV}/dashboard/type`);
    return response.data;
};

export const addType = async (type) => {
    // validation();
    const typeDTO = { id: null, name: type };
    const response = await axios.post(`${API_URL_DEV}/dashboard/type`, typeDTO);
    return response.data;
};

export const deleteType = async (id) => {
    // validation();
    const response = await axios.delete(`${API_URL_DEV}/dashboard/type/${id}`);
    return response.data;
};