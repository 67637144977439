import axios from 'axios';
import { API_URL_DEV } from './authService';

export const getContents = async (header) => {
    // validation();
    const response = await axios.get(`${API_URL_DEV}/dashboard/${header}/content`);
    return response.data;
};

export const addContent = async (header, type, content) => {
    // validation();
    const contentDTO = { header: header, type: type, content: content };
    const response = await axios.post(`${API_URL_DEV}/dashboard/content`, contentDTO);
    return response.data;
};

export const deleteContent = async (id) => {
    // validation();
    const response = await axios.delete(`${API_URL_DEV}/dashboard/content/${id}`);
    return response.data;
};